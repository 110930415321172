var cartFunction = function enableClickOption() {
    $("#order_payment_type_cash_on_delivery, #order_payment_type_credit_card, #order_payment_type_cash").on("click", function(event) {
        event.stopImmediatePropagation()
        var card = $(event.target).val();
        if (card === "pay_now") {
            $(".js-stripe-btn").css("visibility", "visible");
            $(".js-cash_on_btn").css("visibility", "hidden");
        } else {
            $(".js-cash_on_btn").css("visibility", "visible");
            $(".js-stripe-btn").css("visibility", "hidden");
        }

    });

    $(".js-gift-check").on("click", function() {
        var data = $(this).data()
        console.log(data)
        var giftRow = "#gift-row-" + data.itemId
        if ($(this).is(":checked")) {
            $(giftRow).show();
        } else {
            $(giftRow).hide();
        }

    })

    $(".edit_order input[type='radio']").on("click", function(event) {
        $("#delivery_type_dinein input").removeAttr("name");
        $("#delivery_type_pickup select").removeAttr("name");

        event.stopImmediatePropagation();
        if ($(event.target).val() === "delivery") {
            $("#delivery_type_pickup").addClass("hide");
            $("#delivery_type_address").removeClass("hide");
            $("#delivery_type_dinein").addClass("hide");
        } else if ($(event.target).val() === "dinein") {
            $("#delivery_type_pickup").addClass("hide");
            $("#delivery_type_address").addClass("hide");
            $("#delivery_type_dinein").removeClass("hide");
            $("#delivery_type_dinein input").attr("name", "order[delivery_time_eta]");
        } else if ($(event.target).val() === "sameday") {
            window.location.href = '/delivery'
        } else if ($(event.target).val() === "pickup") {
            $("#delivery_type_pickup select").attr("name", "order[delivery_time_eta]");
            $("#delivery_type_pickup").removeClass("hide");
            $("#delivery_type_address").addClass("hide");
            $("#delivery_type_dinein").addClass("hide");
        }
    });

    $('.js-redeem_code').on("click", function(event) {
        event.stopImmediatePropagation();
        var code = $('#giftcard-code').val();
        var twel = code && code.replaceAll('-', '') || '';
        if (twel && twel.length === 12) {
            $.post("/accounts/redeem", { code: code }).done(function(data) {
                if (data.status === 'success') {
                    UIkit.notification({ message: '<span uk-icon=\'icon: check\'></span> Gift Card redeem success', pos: 'top-center', status: 'success' })
                } else {
                    UIkit.notification({ message: '<span uk-icon=\'icon: warning\'></span> Not valid code or already redeemed', pos: 'top-center', status: 'danger' })
                }

            });
        } else {
            UIkit.notification({ message: '<span uk-icon=\'icon: warning\'></span> Please enter valid code', pos: 'top-center', status: 'danger' })
        }
        return false
    })

    $('.js-apply-redeem-gift').on("click", function(event) {
        event.stopImmediatePropagation();
        var code = $('#giftcard-code').val();
        var url = $('#giftcard-code').data().url;
        var twel = code && code.replaceAll('-', '') || '';
        if (twel && twel.length === 12) {
            $.post(url + ".json", { code: code }).done(function(data) {
                if (data.status === 'success') {
                    UIkit.notification({ message: '<span uk-icon=\'icon: check\'></span> Gift Card redeem success', pos: 'top-center', status: 'success' })
                } else {
                    UIkit.notification({ message: '<span uk-icon=\'icon: warning\'></span> Not valid code or already redeemed', pos: 'top-center', status: 'danger' })
                }

            });
        } else {
            UIkit.notification({ message: '<span uk-icon=\'icon: warning\'></span> Please enter valid code', pos: 'top-center', status: 'danger' })
        }
        return false
    })


    $(".dine-btn.js-checkout.update-address-btn").on("click", function(event) {
        event.stopImmediatePropagation();
        $(".dine-btn.submit-address").click();
        return false
    })


    $(".dine-btn.js-checkout.order-payment-btn").on("click", function(event) {
        event.stopImmediatePropagation();
        var cash = $(".edit_order #order_payment_type_cash:checked").val();
        var card = $(".edit_order #order_payment_type_credit_card:checked").val();
        if (cash) {
            $(".order-submit-form .edit_order .js-cash_on_btn").click();
        } else {
            var tip = parseFloat($.trim($('#order_tip').val()));
            $.get("/card/new?tip=" + tip);
        }
    })

    $(".js-order-submit").on("click", function(event) {
        var log = $(event.target).data().login;
        $("#logincheckout").val(log);
        // return false
    });

    $(".js-search-btn").on("click", function(event) {
        var search = $(".uk-search-input").val();
        var shop = $(event.target).data().shop;
        var food = $.trim(search)
        if (food.length > 2) {
            $("#loader").show();

            if (shop) {
                $.get("/shop.js?search=" + search)
            } else {
                $.get("/view-menu.js?search=" + search)
            }
        }
        return false
    })

    $(".uk-search-input").on("keyup", function(event) {
        event.stopImmediatePropagation();
        if (event.keyCode === 13) {
            var search = $(".uk-search-input").val();
            var shop = $(event.target).data().shop;
            var food = $.trim(search)
            if (food.length > 2) {
                $("#loader").show();

                if (shop) {
                    $.get("/shop.js?search=" + search)
                } else {
                    $.get("/view-menu.js?search=" + search)
                }
            }
            return false
        }
    })


    $(".js-variants-price-changes").on("click", function(event) {
        var foodId = $(event.target).data().foodId;
        var variantPrice = $(event.target).data().variantPrice;
        $("#js-food-item-" + foodId).find(".js-price").text(parseFloat(variantPrice).toFixed(2))
        $(event.target).parent().find("a").removeClass("uk-text-bold uk-text-danger")
        $(event.target).addClass('uk-text-bold uk-text-danger')
        return false
    })

    $(".uk-checkbox").on("click", function(event) {
        var ctrl = $(event.target).parent().parent();
        var max_qty = ctrl.data().maxQuantity
        if (ctrl.attr("id")) {
            var checkbox1 = "#" + ctrl.attr("id") + ' input[name="radio2"]:checked';
            if (max_qty < ($(checkbox1).length)) {
                $(event.target).prop('checked', false)
            }
        } else {
            var checkbox2 = ctrl.parent().find("input[name='radio2']:checked")
            if (max_qty < (checkbox2.length)) {
                $(event.target).prop('checked', false)
            }
        }
    })

    $(".uk-radio").on("click", function(event) {
        var foodId = $(event.target).data().foodId;
        var variantId = $(event.target).data().variantId;
        var variantPrice = $(event.target).data().variantPrice;

        $("#js-food-" + foodId + " a.js-add-to-cart").attr("data-variant", true);
        $("#js-food-" + foodId + " a.js-add-to-cart").attr("data-price", variantPrice);
        $("#js-food-" + foodId + " a.js-add-to-cart").attr("data-variant-id", variantId);
        $("#js-food-" + foodId + " .js-variant-price").text(variantPrice)
    })

    $(".uk-checkbox").on("click", function(event) {
        var ctrl = $(event.target).parent().parent();
        var max_qty = ctrl.data().maxQuantity

        var checkbox = "#" + ctrl.attr("id") + ' input[name="radio2"]:checked';
        if (max_qty < ($(checkbox).length)) {
            $(event.target).prop('checked', false)
        }
    });

    $(".select-box--thickness li input").on("click", function(event) {
        if ($(this).is(":checked")) {
            var foodId = $(event.target).data().foodId;
            var variantId = $(event.target).data().variantId;
            var variantPrice = $(event.target).data().variantPrice;
            var variantImage = $(event.target).data().variantImage;
            var cart = $("#js-food-" + foodId + " a.js-add-to-cart");

            $("#js-food-" + foodId + " .product-full-card__price .value").text(variantPrice)
            $("#js-food-" + foodId + " a.js-add-to-cart").attr("data-variant", true);
            $("#js-food-" + foodId + " a.js-add-to-cart").attr("data-price", variantPrice);
            $("#js-food-" + foodId + " a.js-add-to-cart").attr("data-variant-id", variantId);

            // optional 
            $("#js-food-item-" + foodId + " .product-item__price .value").text(variantPrice.toFixed(2))

        } else {
            // $(this).prop('checked', false)
        }
    })
    $(".addon-row .checkbox-custome").on("click", function(event) {
        var selected = $(this).is(":checked")
        if (selected) {
            var input = $(this).parent().parent().find(".js-food-on-qty");
            var input2 = $(this).parent().parent().find(".add-on-qty")

            if (input) {
                input.removeAttr("disabled");
                input.val(1);
                input.addClass('active');
            }

            if (input2) {
                input2.removeAttr("disabled");
                input2.val(1);
                input2.addClass('active');
            }

        } else {
            var input = $(this).parent().parent().find(".js-food-on-qty")
            var input2 = $(this).parent().parent().find(".add-on-qty");
            if (input) {
                input.attr("disabled");
                input.val(0);
                input.removeClass('active');
            }
            if (input2) {
                input2.attr("disabled");
                input2.val(0);
                input2.removeClass('active');
            }
        }


        // var quantity = $(event.target).parent().parent().find(".add-on-qty input").val()
        // var data = { foodId: foodId, id: id, quantity: quantity }
        // $.post("/add_on_items", {
        //     'addon_items': data
        // }).done(function(response) {
        //     // $(".cart-items-number").text(response.cartcount);
        //     // alert("Added to cart successfully..");
        // })
    })


    $("#increase").on("click", function() {
        var value = parseInt($('.qty-number').val(), 10);
        value = isNaN(value) ? 0 : value;
        value++;
        $('.qty-number').val(value);
    })

    $("#decrease").on("click", function() {
        var value = parseInt($('.qty-number').val(), 10);
        value = isNaN(value) ? 0 : value;
        value < 1 ? value = 1 : '';
        value--;
        $('.qty-number').val(value);
    })


    $(".js-add-to-cart").on("click", function(event) {
        event.preventDefault();
        var foodId = $(event.target).data().foodId;
        var variant = $(event.target).data().variant;
        var variantId = $(event.target).data().variantId;
        var quantity = $("#quantity" + foodId).val() || 1;
        var price = $(event.target).data().price;

        var notes = $(".js-uk-textarea").val();
        // {maxLimit: 5, limitQuantity: true, maxQuantity: 2, optionId: 1},
        var notvalid = false
        var optionsQty = $.map($(".js-food-options"), function(limit) {
            var option = $(limit).find(".uk-form-controls").data()
                // console.log(option)
            var calcQty = 0;
            var data = $.map($(limit).find(".uk-checkbox:checked"), function(c) {
                var foodItemId = $(c).val();
                var qty = $(c).parent().parent().find(".js-food-on-qty").val() || 0;
                calcQty = calcQty + parseInt(qty);
                var price = $(c).data().optionPrice;
                console.log(price)

                if (qty == 0) {
                    qty = 1
                }
                return { option_item_id: foodItemId, qty: qty, option_item_price: price }
            })
            if (option.limitQuantity) {
                // console.log(option.maxLimit, calcQty)
                if (option.maxLimit < calcQty) {
                    // alert("Kindly select max " + option.maxLimit + "Quantity")
                    window.UIkit.notification(("Kindly select max " + option.maxLimit + " Quantity"), { status: 'danger' });
                    notvalid = true
                    return false
                }
                return data
            } else {
                return data
            }
        });

        if (notvalid) {
            // alert("not valid")
            return false
        }

        // var options = $.map($(".js-food-options .uk-checkbox:checked"), function(c) {
        //     return $(c).val();
        // })

        var foodQuantityOptions = $.map($("#food_quantity_options .uk-checkbox:checked"), function(c) {
            var quantityFoodId = $(c).data().quantityFoodId;
            var price = $(c).data().quantityFoodPrice;
            var quantity = $(c).parent().parent().find(".js-food-on-qty").val();
            if (quantity == 0) {
                quantity = 1
            }
            return { "food_quantity_item_id": quantityFoodId, quantity: quantity, 'food_quantity_price': price }
        })

        var addons = $.map($("#addon_options .uk-checkbox:checked"), function(c) {
            var relatedFoodId = $(c).data().relatedFoodId;
            var price = $(c).data().relatedFoodPrice;
            var quantity = $(c).parent().parent().find(".add-on-qty").val();
            if (quantity == 0) {
                quantity = 1
            }
            return { "related_item_id": relatedFoodId, quantity: quantity, related_item_price: price }
        })

        console.log("price: ", price, optionsQty, foodQuantityOptions, addons)

        if (parseFloat(price) === 0.0 && optionsQty.length === 0 && foodQuantityOptions.length === 0 && addons.length === 0) {
            return false
        }

        $.post("/cart_items", {
            'cart_item': {
                'food_id': foodId,
                price: price,
                variant_id: variantId,
                variant: variant,
                remarks: notes,
                quantity: quantity,
                option_item_cart_items_attributes: optionsQty,
                food_quantity_cart_items_attributes: foodQuantityOptions,
                addon_cart_items_attributes: addons
            },

        }).done(function(response) {
            // $(".cart-items-number").text(response.cartcount);
            // alert("Added to cart successfully..");
        })
    })
}


$(document).ready(function() {
    console.log("check this out...")
    $.ajaxSetup({
        headers: {
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $(".shop.catalog-filter-row .uk-subnav li").on("click", function() {
        var cat = $(this).data("category");
        var shop = $(this).data("shop");
        $(".js-sorting").attr("data-category", cat);
        $("ul.js-food-list ").html("");
        $("#loader").show();
        if (shop) {
            if (cat) {
                $.get("/shop.js?category=" + cat);
            } else {
                $.get("/shop.js?");
            }
        } else {
            if (cat) {
                $.get("/view-menu.js?category=" + cat);
            } else {
                $.get("/view-menu.js?");
            }
        }

    });

    // $(".dine .uk-subnav").sticky({ topSpacing: 0 });

    $(".dine.catalog-filter-row .uk-subnav li").on("click", function() {
        var cat = $(this).data("category");
        var shop = $(this).data("shop");
        $(".js-sorting").attr("data-category", cat);
        $("#app").html("");
        $("#loader").show();
        if (cat) {
            $.get("/dine.js?category=" + cat);
        } else {
            $.get("/dine.js?");
        }
    });


    function scrollFn() {
        $(".chatbox-panel__main").animate({ scrollTop: $(".chatbox-panel__main")[0].scrollHeight }, 1000);
        $(".chatbox-popup__main").animate({ scrollTop: $(".chatbox-popup__main")[0].scrollHeight }, 1000);
    }

    var items = localStorage.getItem("chatbot");
    if (items && items.length > 0) {
        var itemsObj = JSON.parse(items)
        $.each(itemsObj, function(idex, item) {
            if (item.guest) {
                $(".chatbox-popup__main").append("<span class='chat_msg_item chat_msg_item_user'>" + item.guest + "</span>");
                $(".chatbox-panel__main").append("<span class='chat_msg_item chat_msg_item_user'>" + item.guest + "</span>");
            } else if (item.admin) {
                $(".chatbox-popup__main").append("<span class='chat_msg_item chat_msg_item_admin'>" + item.admin + "</span>");
                $(".chatbox-panel__main").append("<span class='chat_msg_item chat_msg_item_admin'>" + item.admin + "</span>");
            }
        });
    }

    function sendMessage() {
        var text = $(".chatbox-popup__footer textarea").val() || $(".chatbox-panel__footer textarea").val();
        if (text.length === 0) {
            $(".typing").hide();
            return false
        }
        $(".chatbox-popup__footer textarea").val("");
        $(".chatbox-panel__footer textarea").val("");
        $(".ask").text("");
        $(".chatbox-popup__main").append("<span class='chat_msg_item chat_msg_item_user'>" + text + "</span>");
        $(".chatbox-panel__main").append("<span class='chat_msg_item chat_msg_item_user'>" + text + "</span>");
        var items = localStorage.getItem("chatbot");

        if (items && items.length > 0) {
            var itemsObj = JSON.parse(items)
        } else {
            var itemsObj = []
        }
        var te = { guest: text }
        itemsObj.push(te);
        localStorage.setItem('chatbot', JSON.stringify(itemsObj))

        $.get("/chats?message=" + text).done(function(res) {
            var re = { admin: res.response }
            itemsObj.push(re);
            localStorage.setItem('chatbot', JSON.stringify(itemsObj))
            $(".typing").hide();
            $(".chatbox-popup__main").append("<span class='chat_msg_item chat_msg_item_admin'>" + res.response + "</span>");
            $(".chatbox-panel__main").append("<span class='chat_msg_item chat_msg_item_admin'>" + res.response + "</span>");

            scrollFn();
        })
    }

    $("textarea.js-chat-box").on("keyup", function(event) {
        if (event.keyCode === 13) {
            $(".typing").show();
            sendMessage()
            scrollFn();
        }
    });

    $(".chatbox-popup__footer svg.js-chat-box, .chatbox-panel__footer svg.js-chat-box").on("click", function() {
        $(".typing").show();
        sendMessage();
        scrollFn();
    })


    $(".js-sorting").on("change", function() {
        var cat = $(this).data("category");
        var shop = $(this).data("shop");
        var sort = $(this).val();
        $("ul.js-food-list ").html("");
        $("#loader").show();
        if (shop) {
            if (cat) {
                $.get("/shop.js?category=" + cat + "&sort=" + sort);
            } else {
                $.get("/shop.js?&sort=" + sort);
            }
        } else {
            if (cat) {
                $.get("/view-menu.js?category=" + cat + "&sort=" + sort);
            } else {
                $.get("/view-menu.js?&sort=" + sort);
            }
        }

    });


    // call onloading this function
    cartFunction();
    window.cartFunction = cartFunction;

    $(".js-delivery-type input").on("click", function(event) {
        if ($(event.target).is(":checked")) {
            var ptype = $(event.target).val();
            var hour = $("#delivery_time__4i").val()
            var min = $("#delivery_time__5i").val()
            $.post("/update_delivery_type", { 'delivery_type': ptype, hour: hour, min: min }).done(function(response) {
                // $(".cart-items-number").text(response.cartcount);
                // alert("Added to cart successfully..");
            })
        }
    });

    $("#delivery_time__4i, #delivery_time__5i").on("change", function(event) {
        var hour = $("#delivery_time__4i").val()
        var min = $("#delivery_time__5i").val()
        $.post("/update_delivery_type", { hour: hour, min: min }).done(function(response) {})
    });

    if ($("#user_billing_attributes_is_same_shipping_address").length > 0) {
        if ($("#user_billing_attributes_is_same_shipping_address").is(":checked")) {
            $(".shipping-address").hide();
            $(".shipping-address .input").removeClass("required")
            $(".shipping-address .input").find("input").removeAttr("required")
            $(".shipping-address .input").find("select").removeAttr("required")
            $(".shipping-address .input").find("textarea").removeAttr("required")
        } else {
            $(".shipping-address").show();
        }
    }

    $("#user_billing_attributes_is_same_shipping_address").on("click", function(event) {
        if ($(event.target).is(":checked")) {
            $(".shipping-address").hide();
            $(".shipping-address .input").removeClass("required")
            $(".shipping-address .input").find("input").removeAttr("required")
            $(".shipping-address .input").find("select").removeAttr("required")
            $(".shipping-address .input").find("textarea").removeAttr("required")
        } else {
            $(".shipping-address").show();
            $(".shipping-address .input").addClass("required")
            $(".shipping-address .input").find("input").attr("required", "required")
            $(".shipping-address .input").find("select").attr("required", "required")
            $(".shipping-address .input").find("textarea").attr("required", "required")
        }
    });

    $('.product-item__toggle button').on('click', function() {
        $(this).offsetParent('.product-item').find('.product-item__active').toggleClass('is-show');
        $(this).parent('.product-item__toggle').toggleClass('is-show');
    });
    $('.product-item__whish').on('click', function() {
        $(this).toggleClass('is-active');
    });
    $('.product-full-card__toggle button').on('click', function() {
        $(this).offsetParent('.product-full-card').find('.product-full-card__active').toggleClass('is-show');
        $(this).parent('.product-full-card__toggle').toggleClass('is-show');
    });
    $('.product-full-card__whish').on('click', function() {
        $(this).toggleClass('is-active');
    });
    $('.counter .minus').on('click', function() {
        var $input = $(this).parent().find('input');
        var count = parseInt($input.val()) - 1;
        count = count < 1 ? 1 : count;
        $input.val(count);
        $input.change();
        return false;
    });
    $('.counter .plus').on('click', function() {
        var $input = $(this).parent().find('input');
        $input.val(parseInt($input.val()) + 1);
        $input.change();
        return false;
    });
    $('.js-hidden-box').each(function() {
        $(this).children('div').hide();
        $(this).children('div').slice(0, 6).show();
        $(this).next('.js-hidden-btn').on('click', function(e) {
            e.preventDefault();
            $(this).prev('.js-hidden-box').children('div:hidden').slice(0, 3).slideDown();
            var elHidden = $(this).prev('.js-hidden-box').children('div:hidden').length;

            if (elHidden <= 0) {
                $(this).hide();
            }
        });
    });
    $('.js-checkbox').on('click', function() {
        $(this).toggleClass('is-checked');
    });
});


$(document).on('ready turbolinks:load', function() {
    cartFunction();
});

$(document).on('ready', function() {
    cartFunction();
});

/////////////////////////////////////////////////////////////////
// Preloader
/////////////////////////////////////////////////////////////////

var $preloader = $('#page-preloader'),
    $spinner = $preloader.find('.spinner-loader');
$spinner.fadeOut();
$preloader.delay(50).fadeOut('slow');


const mediaQueryTablet = window.matchMedia('(max-width: 768px)');

function handleTabletChange(e) {
    if (e.matches) {
        console.log('Media Query 768!');
    }
}

mediaQueryTablet.addListener(handleTabletChange);
handleTabletChange(mediaQueryTablet);