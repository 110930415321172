// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()
import flatpickr from "flatpickr";

window.$ = $;
window.jQuery = $;

require("packs/admin/jquery.easing.min")
require("packs/store/menu-main")
require("packs/store/foxycomplete")
require("packs/store/searches")
require("packs/store/fontawesome.all.min")
require("packs/store/wpfront-scroll-top.min")
require("packs/store/waypoints.min")

require("packs/store/jquery.smartmenus.min")
require("packs/store/jquery.sticky.min")
require("packs/store/number-format")

console.log("test")

$(document).ready(function() {
    console.log("Imported ....");
    console.log("Imported ")

    $(".burger-toggle").on("click", function(i) {
        $(".burger-toggle").toggleClass("elementor-active")
        $(".elementor-nav-menu--dropdown.elementor-nav-menu__container").css({ width: "100%", top: "20px" })
    });



    // number format
    var inputElement = document.getElementById('phoneNumber');
    if (inputElement) {
        inputElement.addEventListener('keydown', window.enforceFormat);
        inputElement.addEventListener('keyup', window.formatToPhone);
    }


    $(".qty-item").on("focusout", function(i) {
        var formNumber = $(this).data("form")
        $(this).parents().closest("tbody").find("#edit_cart_item_" + formNumber).submit()
    })

    // number format
    var inputElement = document.getElementById('phoneNumber2');
    if (inputElement) {
        inputElement.addEventListener('keydown', window.enforceFormat);
        inputElement.addEventListener('keyup', window.formatToPhone);
    }


    $(".chatbox-open").on("click", function() {
        $(".chatbox-popup, .chatbox-close").fadeIn();
        $(".chatbox-panel__main").animate({ scrollTop: $(".chatbox-panel__main")[0].scrollHeight }, 1000);
        $(".chatbox-popup__main").animate({ scrollTop: $(".chatbox-popup__main")[0].scrollHeight }, 1000);
    });

    $(".chatbox-close").on("click", function() {
        $(".chatbox-popup, .chatbox-close").fadeOut();
    });

    $(".chatbox-maximize").on("click", function() {
        $(".chatbox-popup, .chatbox-open, .chatbox-close").fadeOut();
        $(".chatbox-panel").fadeIn();
        $(".chatbox-panel").css({ display: "flex" });
    });

    $(".chatbox-minimize").on("click", function() {
        $(".chatbox-panel").fadeOut();
        $(".chatbox-popup, .chatbox-open, .chatbox-close").fadeIn();
    });

    $(".chatbox-panel-close").on("click", function() {
        $(".chatbox-panel").fadeOut();
        $(".chatbox-open").fadeIn();
    });


});
// $(document).on('ready turbolinks:load', function() {
// window.UIkit = UIkit;
// console.log("Trbos Works")
// $.SmartMenus.destroy()
// $(".elementor-item").smartmenus("refresh")
// var $menu = $('.has-submenu');

// $menu.smartmenus('refresh');

// })

$(document).on('ready', function() {
    // window.UIkit = UIkit;
    console.log("Turbos Works")
    console.log("Imported 1111")
        // console.log(UIkit)
        // if (window.UIkit) {
        //     UIkit.use(Icons);
        // }

    var $menu = $('.elementor-nav-menu');

    $menu.smartmenus('refresh');


    flatpickr(".flatpickr-input", {});
    flatpickr(".flatpickr-time", {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        minTime: "11:00",
        maxTime: "23:00",
    });


    // chatbox


    $(".chatbox-open").on("click", function() {
        alert("dd")
        $(".chatbox-popup, .chatbox-close").fadeIn();
    });

    $(".chatbox-close").on("click", function() {
        $(".chatbox-popup, .chatbox-close").fadeOut();
    });

    $(".chatbox-maximize").on("click", function() {
        $(".chatbox-popup, .chatbox-open, .chatbox-close").fadeOut();
        $(".chatbox-panel").fadeIn();
        $(".chatbox-panel").css({ display: "flex" });
    });

    $(".chatbox-minimize").on("click", function() {
        $(".chatbox-panel").fadeOut();
        $(".chatbox-popup, .chatbox-open, .chatbox-close").fadeIn();
    });

    $(".chatbox-panel-close").on("click", function() {
        $(".chatbox-panel").fadeOut();
        $(".chatbox-open").fadeIn();
    });

});